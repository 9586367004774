exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".style-root-1c5bw {\n  padding: 20px;\n  margin: 10% auto 0;\n  max-width: 600px;\n  border-radius: 4px;\n  text-align: center;\n  box-sizing: border-box;\n  background-color: #fff;\n  box-shadow: 0 18px 64px -38px rgba(14, 14, 20, 0.12), 0 10px 24px -10px rgba(14, 14, 20, 0.14); }\n", "", {"version":3,"sources":["/var/www/b2b-dashboard/src/pages/NotFound/style.scss"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,mBAAmB;EACnB,iBAAiB;EACjB,mBAAmB;EACnB,mBAAmB;EACnB,uBAAuB;EACvB,uBAAuB;EACvB,+FAA+F,EAAE","file":"style.scss","sourcesContent":[".root {\n  padding: 20px;\n  margin: 10% auto 0;\n  max-width: 600px;\n  border-radius: 4px;\n  text-align: center;\n  box-sizing: border-box;\n  background-color: #fff;\n  box-shadow: 0 18px 64px -38px rgba(14, 14, 20, 0.12), 0 10px 24px -10px rgba(14, 14, 20, 0.14); }\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"root": "style-root-1c5bw"
};